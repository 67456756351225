import { useNavigate } from "react-router-dom";

import EmptyTable from "components/EmptyTable";
import { Table, Heading, FieldName, Row, FieldValueWithBorder } from "components/LayoutParts";
import {  PIT_PORT_OWNER_USER_ROLE_LABEL } from "pages/PitPort/OwnerEntity/OwnerUser/constants";

/**
 * 貸主ユーザーのテーブルの列
 */
const userTableColumn = [
  {
    label: "名前",
    flex: 23,
  },
  {
    label: "フリガナ",
    flex: 23,
  },
  {
    label: "メールアドレス",
    flex: 23,
  },
  {
    label: "ロール",
    flex: 13,
  },
  {
    label: "ステータス",
    flex: 11,
  },
];

/**
 * 貸主ユーザーのテーブル
 */
const DataTable = ({
  userList,
  ownerEntityId,
}: {
  userList: OwnerUser[];
  ownerEntityId: string;
}) => {
  const navigate = useNavigate();

  const onClickRow = (userId: string, ownerEntityId: string) =>
    navigate(`/owner_entity/${ownerEntityId}/owner_user/${userId}/edit`);

  const onClickAddUser = () => navigate(`/owner_entity/${ownerEntityId}/owner_user/create`);

  return (
    <Table>
      <Heading>
        {userTableColumn.map((column, i) => (
          <FieldName
            key={column.label}
            flex={column.flex}
            lastColumn={i === userTableColumn.length - 1}
          >
            {column.label}
          </FieldName>
        ))}
      </Heading>
      {userList.length > 0 ? (
        userList.map((user) => (
          <Row key={user.id} onClick={() => onClickRow(user.id, ownerEntityId)}>
            <FieldValueWithBorder flex={userTableColumn[0].flex}>
              {user.lastName + user.firstName}
            </FieldValueWithBorder>
            <FieldValueWithBorder flex={userTableColumn[1].flex}>
              {user.lastNameFurigana + user.firstNameFurigana}
            </FieldValueWithBorder>
            <FieldValueWithBorder flex={userTableColumn[2].flex}>{user.email}</FieldValueWithBorder>
            <FieldValueWithBorder flex={userTableColumn[3].flex}>
              {PIT_PORT_OWNER_USER_ROLE_LABEL[user.role]}
            </FieldValueWithBorder>
            <FieldValueWithBorder flex={userTableColumn[4].flex}>
              {user.deletedAt ? '削除済み' : '有効'}
            </FieldValueWithBorder>
          </Row>
        ))
      ) : (
        <EmptyTable
          label="ユーザを追加"
          message="ユーザが見つかりません"
          handleClick={onClickAddUser}
        />
      )}
    </Table>
  );
};

export default DataTable;