import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import Button from "components/Button";
import Loading from "components/Loading";
import { useFindOwnerUsers } from "pages/PitPort/OwnerEntity/api";
import DataTable from "pages/PitPort/OwnerEntity/OwnerUser/DataTable";

const ButtonWrap = styled.div`
  margin-left: auto;
`;

/**
 * 貸主ユーザー一覧画面(仮)
 */
const OwnerUsers = () => {
  const { id: ownerEntityId } = useParams();
  const { data } = useFindOwnerUsers({ ownerEntityId: ownerEntityId ?? "1" });
  const navigate = useNavigate();
  const onClickUserAdd = () => navigate(`/pit_port/owner_entities/${ownerEntityId}/owner_users/create`);

  return (
    <>
      <ButtonWrap>
        <Button label="貸主ユーザを追加" onClick={onClickUserAdd} type="primary" />
      </ButtonWrap>
      <br />
      {data ? (
        <DataTable
          userList={data}
          ownerEntityId={ownerEntityId ?? ""}
        />
      ) : (
        <Loading />
      )}
    </>
  );
};

export default OwnerUsers;
