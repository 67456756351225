import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

import { FirebaseContext } from "contexts/FirebaseContext";

// Landit admin
const landitAdminApp = initializeApp({
  apiKey: process.env.REACT_APP_LANDIT_ADMIN_API_KEY,
  appId: process.env.REACT_APP_LANDIT_ADMIN_APP_ID,
  authDomain: process.env.REACT_APP_LANDIT_ADMIN_AUTH_DOMAIN,
  messagingSenderId: process.env.REACT_APP_LANDIT_ADMIN_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_LANDIT_ADMIN_PROJECT_ID,
});
const analytics = getAnalytics(landitAdminApp);
const auth = getAuth(landitAdminApp);
const db = getFirestore(landitAdminApp);
auth.languageCode = "jp";
const provider = new GoogleAuthProvider();

if (process.env.REACT_APP_ENV === "local") {
  connectAuthEmulator(auth, "http://localhost:9199");
}

const FirebaseProvider = ({ children }: { children: React.ReactNode }) => {
  const value = { analytics, auth, provider, db };
  return <FirebaseContext.Provider value={value}>{children}</FirebaseContext.Provider>;
};

export default FirebaseProvider;
